import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import ja from 'date-fns/locale/ja';
import "react-datepicker/dist/react-datepicker.css";

import './date-pick.component.scss';

registerLocale('ja', ja);

export const DatePick = ({ value, onChange, startYear, endYear, placeholder, label, ...rest }) => {
    if (!startYear) {
        startYear = 1900;
    }
    if (!endYear) {
        endYear = new Date().getFullYear();
    }
    const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => endYear - i);
    const months = [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月"
    ];

    //mostly from https://reactdatepicker.com/#example-custom-header
    const customHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
    }) => (
        <div
            style={{
                margin: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <button className="datepick-btn" type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {'<'}
            </button>
            <select
                className="datepick-select"
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
            >
                {years.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <select
                className="datepick-select"
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                }
            >
                {months.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <button className="datepick-btn" type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {'>'}
            </button>
        </div>
    );

    const DateInput = React.forwardRef((props, ref) => {
        return (
            <div className="date-pick-input-container">
                <label className="t-sans t-grey9">{label}</label>
                <div className="date-pick-input-box">
                    <input {...props}/>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.08086 7.0308L9.75727 3.3544C10.0801 3.03155 10.0801 2.4994 9.75727 2.17659C9.43442 1.85374 8.90227 1.85374 8.57946 2.17659L5.49195 5.25411L2.40445 2.16661C2.08161 1.84377 1.54945 1.84377 1.22664 2.16661C1.06522 2.32804 0.979493 2.54661 0.979493 2.75554C0.979493 2.96446 1.06484 3.18304 1.22664 3.34446L4.90305 7.03094C5.2259 7.35378 5.75805 7.35378 6.08086 7.03094L6.08086 7.0308Z" fill="#404455"/>
                    </svg>
                </div>
            </div>
        );
    });

    return <DatePicker
        locale="ja"
        renderCustomHeader={customHeader}
        customInput={<DateInput label={label} />}
        dateFormat="MM月dd日"
        selected={value}
        onChange={onChange}
        placeholderText={placeholder}
    />;
};