import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import dayjs from 'dayjs'

import { OrganizationPanelLayout } from "../../components/organization-panel-layout.component";
import { Pagination } from '../../components/crizit-paginate.component';

import { SearchBox } from '../../components/organization/search-box.component';
import { Dropdown } from '../../components/organization/dropdown.component';
import { DatePick } from '../../components/organization/date-pick.component';
import { TableHeader } from '../../components/organization/table-header.component';
import { StatusLabel } from '../../components/organization/status-label.component';
import { EditModal } from '../../components/organization/edit-modal.component';

import { useUser } from '../../hooks/use-user.hook';
import { getFullNameKanji } from '../../utils/patient-info.format';

import { downloadResultPdf, fetchOrganizationRegistrations, invite, organizationAddPatient, getOrganizationReport } from '../../services/organization.service';
import { PopUpMessage } from '../../components/pop-up-message.component';

const getDateObj = dateStr => dateStr ? new Date(dateStr) : null;

const statusOptions = t => {
    return [
        {value: 'ALL', label :t('All')},
        {value: 'COMPLETED', label :t('Completed')},
        {value: 'COMPLETED_NEGATIVE', label :t('Completed Negative')},
        {value: 'COMPLETED_POSITIVE', label :t('Completed Positive')},
        {value: 'IN_TESTING', label :t('In testing')},
        {value: 'RECEIVED_IN_LAB', label :t('Received in lab')},
        {value: 'PATIENT_COMPLETED', label :t('Patient Completed')},
        {value: 'IN_PROGRESS', label :t('In Progress')},
    ];
};

const isEditable = status => {
    if (
        status === 'RECEIVED_IN_LAB' ||
        status === 'PENDING_FOLLOW_UP' ||
        status === 'IN_TESTING' ||
        status === 'COMPLETED_NEGATIVE' ||
        status === 'COMPLETED_POSITIVE' ||
        status === 'COMPLETED'
    ) {
        return false;
    }
    return true;
}

export default function OrganizationPanel() {
    const { t } = useI18next();
    const [ testingDateTo, setTestingDateTo ] = useState('');
    const [ testingDateFrom, setTestingDateFrom ] = useState('');
    const [ isLoadingRegistrations, setIsLoadingRegistrations ] = useState(false);
    const [ organizationId, setOrganizationId ] = useState(null);
    const [ registrations, setRegistrations ] = useState([]);
    const [ formErrors, setFormErrors ] = useState(null);
    const [ selectedInventory, setSelectedInventory ] = useState(null);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ sortBy, setSortBy ] = useState('barcode');
    const [ sortByDirection, setSortByDirection ] = useState('ASC');
    const [ keywords, setKeywords ] = useState('');
    const [ status, setStatus ] = useState('ALL');
    const [ isInvited, setIsInvited ] = useState(false);
    const popUpTimerRef = useRef();
    const { user } = useUser();

    const loadRegistrations = () => {
        const filters = {};
        filters.page = currentPage;
        filters.sortBy = sortBy;
        
        if (keywords) {
            filters.keywords = keywords;
        }

        if (status === 'ALL') {
            delete filters.status;
        } else {
            filters.status = status;
        }

        if (testingDateFrom) {
            filters.testingDateFrom = testingDateFrom;
        }
        if (testingDateTo) {
            filters.testingDateTo = testingDateTo;
        }

        filters.sortByDirection = sortByDirection;

        fetchOrganizationRegistrations(filters)
            .then(response => {
                setRegistrations(response.data.items);
                setTotalPages(Number(response.data.meta?.totalPages || 1));
            })
            .catch(error => console.error('error ', error));
    };

    useEffect(() => {
        if (user && user.organization) {
            setOrganizationId(user.organization.id);
            setIsLoadingRegistrations(true);
            loadRegistrations();
        }
    }, [ user, organizationId ]);

    useEffect(() => {
        if (isInvited) {
            if(popUpTimerRef.current) {
                clearTimeout(popUpTimerRef.current);
            }
            popUpTimerRef.current = setTimeout(() => {
                setIsInvited(false);
            },5000);
        }
    }, [ isInvited ]);

    useEffect(() => {
        loadRegistrations();
    }, [ keywords, status, sortBy, testingDateFrom, testingDateTo, sortByDirection, currentPage ]);

    const handleTableHeaderClick = useCallback(headerName => {
        if (headerName !== sortBy) {
            setSortBy(headerName);
            setSortByDirection('ASC');    
        } else {
            setSortByDirection(sortByDirection === 'ASC' ? 'DESC' : 'ASC');
        }
    }, [ sortBy, sortByDirection ]);

    const handleInviteClick = email => {
        invite(email)
            .then(response => setIsInvited(true))
            .catch(error => console.error('invite ', error ));
    }


    const handleNewPatientAdd = patient => {
        setFormErrors(null);
        organizationAddPatient(patient)
            .then(reponse => {
                setSelectedInventory(null);
                loadRegistrations();
            })
            .catch(error => setFormErrors(error.response.data.message));
    }

    const handleDownloadResult = registrationId => {
        if (registrationId) {
            downloadResultPdf(registrationId);
        }   
    }

    const handleDownloadCsv = () => {
        const filters = {};
        if (status === 'ALL') {
            delete filters.status;
        } else {
            filters.status = status;
        }

        filters.page = currentPage;
        filters.sortBy = sortBy;
        
        if (keywords) {
            filters.keywords = keywords;
        }

        if (testingDateFrom) {
            filters.testingDateFrom = testingDateFrom;
        }
        if (testingDateTo) {
            filters.testingDateTo = testingDateTo;
        }

        getOrganizationReport(filters)
            .then(response => console.log(response))
            .catch(error => console.error('download csv ', error));
    }

    return (
        <OrganizationPanelLayout>
            <section className="organization-registrations animateIn">
                {isInvited && <PopUpMessage msg={t('Invitation Email has been sent')} />}

                { selectedInventory && (
                    <EditModal
                        inventory={selectedInventory}
                        onSubmit={handleNewPatientAdd}
                        errors={formErrors}
                        onClose={() => setSelectedInventory(null)}
                    />
                )}
                <h2 className="t-h2 t-grey9 heading">PCR検査リスト</h2>
                <div className="registrations-filters">
                    <SearchBox
                        keywords={keywords}
                        onChange={e => {
                            setCurrentPage(1);
                            setKeywords(e.target.value);
                        }} />
                    <div className="registrations-filters__date-range">
                        <DatePick
                            label={t('Testing date')}
                            placeholder={t('Select date')}
                            value={getDateObj(testingDateFrom)}
                            startYear={2021}
                            onChange={value => {
                                setCurrentPage(1);
                                setTestingDateFrom(value.toDateString());
                            }}
                        />
                        <div className="spacer">
                            <span className="t-sans t-bold">〜</span>
                        </div>
                        <DatePick
                            label=''
                            placeholder={t('Select date')}
                            value={getDateObj(testingDateTo)}
                            startYear={2021}
                            onChange={value => {
                                setCurrentPage(1);
                                setTestingDateTo(value.toDateString());
                            }}
                        />
                    </div>
                    <Dropdown
                        options={statusOptions(t)}
                        label="Test status"
                        placeholder="Select status"
                        onChange={option => {
                            setCurrentPage(1);
                            setStatus(option.value);
                        }}
                    />
                    <button className="download t-sans t-grey9" type="button" onClick={handleDownloadCsv}>
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.74196 0H8.53325C8.04287 0 7.64497 0.401357 7.64497 0.895999V9.93216L4.84369 7.10654C4.49583 6.75651 3.93334 6.75651 3.58633 7.10654L3.43955 7.25544L3.43955 7.25546H3.4387C3.09169 7.60549 3.09169 8.17287 3.43955 8.52289L7.79864 12.919C7.80051 12.9209 7.80239 12.9228 7.80428 12.9246C7.96484 13.1622 8.23154 13.3494 8.53325 13.3494H8.74196C9.08618 13.3494 9.38483 13.1517 9.53228 12.8627L13.8358 8.52266C14.1828 8.17263 14.1828 7.60525 13.8358 7.25523L13.6882 7.10632L13.6882 7.1063C13.3412 6.75627 12.7787 6.75627 12.4317 7.1063L9.63025 9.93207V0.895999C9.63025 0.494642 9.23235 0 8.74196 0ZM1.42779 13.8176H0.888194L0.888281 13.8176C0.3979 13.8176 0 14.2189 0 14.7136V17.2604C0 17.7551 0.3979 18.1564 0.888281 18.1564H16.4854C16.9767 18.1564 17.3746 17.7551 17.3746 17.2604V14.7136C17.3746 14.2189 16.9767 13.8176 16.4854 13.8176H15.9458C15.4555 13.8176 15.0576 14.2189 15.0576 14.7136V15.987L2.31607 15.8201V14.7136C2.31607 14.2189 1.91902 13.8176 1.42779 13.8176Z" fill="#59697A"/>
                        </svg>
                        {t('Download CSV')}
                    </button>
                </div>
                <table className="registrations-table">
                    <TableHeader onCellClick={s => handleTableHeaderClick(s)} />
                    { registrations && registrations.length > 0 && (
                        <tbody className="registration-table-body">
                            {registrations.map(inventory => (
                                <tr key={inventory.id} className="registration">
                                    <td className="t-sans t-grey9 registration__barcode">{inventory?.barcode}</td>

                                    <td className="t-sans t-grey9 registration__name">{inventory?.registration?.patient ? getFullNameKanji(inventory.registration.patient) : ''}</td>
                                    <td className="t-sans t-grey9 registration__registered-date">{inventory?.registration?.testingDate ? dayjs(inventory.registration.testingDate).format('YYYY/MM/DD') : ''}</td>
                                    <td className="t-sans t-grey9 registration__email">{inventory?.registration?.patient ? inventory?.registration?.patient.emailAddress : ''}</td>
                                    <td className="registration__status">
                                        <StatusLabel registration={inventory?.registration} />
                                    </td>
                                    <td className="registration__button registration__button--edit">
                                        {isEditable(inventory?.registration?.status) ? (
                                            <button type="button" onClick={() => setSelectedInventory(inventory)}>
                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.7273 18H0.606061C0.445323 18 0.29117 17.9298 0.177511 17.8048C0.0638527 17.6797 0 17.5102 0 17.3334C0 17.1565 0.0638527 16.987 0.177511 16.8619C0.29117 16.7369 0.445323 16.6667 0.606061 16.6667H12.7273C12.888 16.6667 13.0422 16.7369 13.1558 16.8619C13.2695 16.987 13.3333 17.1565 13.3333 17.3334C13.3333 17.5102 13.2695 17.6797 13.1558 17.8048C13.0422 17.9298 12.888 18 12.7273 18Z" fill="#66AC46"/>
                                                    <path d="M0.732732 15.1849C0.654694 15.1079 0.596834 15.0129 0.564275 14.9083C0.531716 14.8036 0.525462 14.6926 0.546066 14.5849L1.38607 9.03157C1.40672 8.89036 1.47215 8.7595 1.57273 8.65824L9.69273 0.584907C10.0677 0.210372 10.5761 0 11.1061 0C11.6361 0 12.1444 0.210372 12.5194 0.584907L15.3327 3.39824C15.7073 3.77324 15.9176 4.28157 15.9176 4.81157C15.9176 5.34158 15.7073 5.84991 15.3327 6.22491L7.23273 14.3449C7.13148 14.4455 7.00062 14.5109 6.8594 14.5316L1.33273 15.3716C1.22508 15.3922 1.11402 15.3859 1.00936 15.3534C0.904706 15.3208 0.809692 15.2629 0.732732 15.1849ZM2.66607 9.47824L1.9994 13.9182L6.4394 13.2516L14.4394 5.29157C14.5636 5.16667 14.6333 4.9977 14.6333 4.82157C14.6333 4.64545 14.5636 4.47648 14.4394 4.35157L11.5727 1.51157C11.4478 1.38741 11.2789 1.31771 11.1027 1.31771C10.9266 1.31771 10.7576 1.38741 10.6327 1.51157L2.66607 9.47824Z" fill="#66AC46"/>
                                                    <path d="M13.4602 7.17155L8.74684 2.45822C8.68468 2.39606 8.63538 2.32227 8.60174 2.24105C8.5681 2.15984 8.55078 2.07279 8.55078 1.98489C8.55078 1.89698 8.5681 1.80993 8.60174 1.72872C8.63538 1.6475 8.68468 1.57371 8.74684 1.51155C8.809 1.44939 8.88279 1.40009 8.96401 1.36645C9.04522 1.33281 9.13227 1.31549 9.22018 1.31549C9.30808 1.31549 9.39513 1.33281 9.47634 1.36645C9.55756 1.40009 9.63135 1.44939 9.69351 1.51155L14.4068 6.22489C14.5324 6.35042 14.6029 6.52068 14.6029 6.69822C14.6029 6.87575 14.5324 7.04602 14.4068 7.17155C14.2813 7.29709 14.111 7.36761 13.9335 7.36761C13.756 7.36761 13.5857 7.29709 13.4602 7.17155Z" fill="#66AC46"/>
                                                    <path d="M6.39335 14.2449L1.67335 9.52489C1.54919 9.39999 1.47949 9.23102 1.47949 9.05489C1.47949 8.87877 1.54919 8.7098 1.67335 8.58489C1.73533 8.52241 1.80906 8.47281 1.8903 8.43897C1.97154 8.40512 2.05868 8.3877 2.14669 8.3877C2.2347 8.3877 2.32183 8.40512 2.40307 8.43897C2.48431 8.47281 2.55805 8.52241 2.62002 8.58489L7.33335 13.2982C7.39584 13.3602 7.44544 13.4339 7.47928 13.5152C7.51313 13.5964 7.53055 13.6836 7.53055 13.7716C7.53055 13.8596 7.51313 13.9467 7.47928 14.0279C7.44544 14.1092 7.39584 14.1829 7.33335 14.2449C7.20845 14.3691 7.03948 14.4388 6.86335 14.4388C6.68723 14.4388 6.51826 14.3691 6.39335 14.2449Z" fill="#66AC46"/>
                                                </svg>
                                            </button>
                                        ) : (
                                            <button type="button" className="disabled">
                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.7273 18H0.606061C0.445323 18 0.29117 17.9298 0.177511 17.8048C0.0638527 17.6797 0 17.5102 0 17.3334C0 17.1565 0.0638527 16.987 0.177511 16.8619C0.29117 16.7369 0.445323 16.6667 0.606061 16.6667H12.7273C12.888 16.6667 13.0422 16.7369 13.1558 16.8619C13.2695 16.987 13.3333 17.1565 13.3333 17.3334C13.3333 17.5102 13.2695 17.6797 13.1558 17.8048C13.0422 17.9298 12.888 18 12.7273 18Z" fill="#DBDCE1"/>
                                                    <path d="M0.732732 15.1849C0.654694 15.1079 0.596834 15.0129 0.564275 14.9083C0.531716 14.8036 0.525462 14.6926 0.546066 14.5849L1.38607 9.03157C1.40672 8.89036 1.47215 8.7595 1.57273 8.65824L9.69273 0.584907C10.0677 0.210372 10.5761 0 11.1061 0C11.6361 0 12.1444 0.210372 12.5194 0.584907L15.3327 3.39824C15.7073 3.77324 15.9176 4.28157 15.9176 4.81157C15.9176 5.34158 15.7073 5.84991 15.3327 6.22491L7.23273 14.3449C7.13148 14.4455 7.00062 14.5109 6.8594 14.5316L1.33273 15.3716C1.22508 15.3922 1.11402 15.3859 1.00936 15.3534C0.904706 15.3208 0.809692 15.2629 0.732732 15.1849ZM2.66607 9.47824L1.9994 13.9182L6.4394 13.2516L14.4394 5.29157C14.5636 5.16667 14.6333 4.9977 14.6333 4.82157C14.6333 4.64545 14.5636 4.47648 14.4394 4.35157L11.5727 1.51157C11.4478 1.38741 11.2789 1.31771 11.1027 1.31771C10.9266 1.31771 10.7576 1.38741 10.6327 1.51157L2.66607 9.47824Z" fill="#DBDCE1"/>
                                                    <path d="M13.4602 7.17155L8.74684 2.45822C8.68468 2.39606 8.63538 2.32227 8.60174 2.24105C8.5681 2.15984 8.55078 2.07279 8.55078 1.98489C8.55078 1.89698 8.5681 1.80993 8.60174 1.72872C8.63538 1.6475 8.68468 1.57371 8.74684 1.51155C8.809 1.44939 8.88279 1.40009 8.96401 1.36645C9.04522 1.33281 9.13227 1.31549 9.22018 1.31549C9.30808 1.31549 9.39513 1.33281 9.47634 1.36645C9.55756 1.40009 9.63135 1.44939 9.69351 1.51155L14.4068 6.22489C14.5324 6.35042 14.6029 6.52068 14.6029 6.69822C14.6029 6.87575 14.5324 7.04602 14.4068 7.17155C14.2813 7.29709 14.111 7.36761 13.9335 7.36761C13.756 7.36761 13.5857 7.29709 13.4602 7.17155Z" fill="#DBDCE1"/>
                                                    <path d="M6.39335 14.2449L1.67335 9.52489C1.54919 9.39999 1.47949 9.23102 1.47949 9.05489C1.47949 8.87877 1.54919 8.7098 1.67335 8.58489C1.73533 8.52241 1.80906 8.47281 1.8903 8.43897C1.97154 8.40512 2.05868 8.3877 2.14669 8.3877C2.2347 8.3877 2.32183 8.40512 2.40307 8.43897C2.48431 8.47281 2.55805 8.52241 2.62002 8.58489L7.33335 13.2982C7.39584 13.3602 7.44544 13.4339 7.47928 13.5152C7.51313 13.5964 7.53055 13.6836 7.53055 13.7716C7.53055 13.8596 7.51313 13.9467 7.47928 14.0279C7.44544 14.1092 7.39584 14.1829 7.33335 14.2449C7.20845 14.3691 7.03948 14.4388 6.86335 14.4388C6.68723 14.4388 6.51826 14.3691 6.39335 14.2449Z" fill="#DBDCE1"/>
                                                </svg>
                                            </button>
                                        )}
                                    </td>
                                    <td className="registration__button registration__button--download">
                                        {inventory?.registration?.result ? (
                                            <button type="button" onClick={() => handleDownloadResult(inventory?.registration?.id)}>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.74196 0H8.53325C8.04287 0 7.64497 0.401357 7.64497 0.895999V9.93216L4.84369 7.10654C4.49583 6.75651 3.93334 6.75651 3.58633 7.10654L3.43955 7.25544L3.43955 7.25546H3.4387C3.09169 7.60549 3.09169 8.17287 3.43955 8.52289L7.79864 12.919C7.80051 12.9209 7.80239 12.9228 7.80428 12.9246C7.96484 13.1622 8.23154 13.3494 8.53325 13.3494H8.74196C9.08618 13.3494 9.38483 13.1517 9.53228 12.8627L13.8358 8.52266C14.1828 8.17263 14.1828 7.60525 13.8358 7.25523L13.6882 7.10632L13.6882 7.1063C13.3412 6.75627 12.7787 6.75627 12.4317 7.1063L9.63025 9.93207V0.895999C9.63025 0.494642 9.23235 0 8.74196 0ZM1.42779 13.8176H0.888194L0.888281 13.8176C0.3979 13.8176 0 14.2189 0 14.7136V17.2604C0 17.7551 0.3979 18.1564 0.888281 18.1564H16.4854C16.9767 18.1564 17.3746 17.7551 17.3746 17.2604V14.7136C17.3746 14.2189 16.9767 13.8176 16.4854 13.8176H15.9458C15.4555 13.8176 15.0576 14.2189 15.0576 14.7136V15.987L2.31607 15.8201V14.7136C2.31607 14.2189 1.91902 13.8176 1.42779 13.8176Z" fill="#E0855E"/>
                                                </svg>
                                            </button>
                                        ) : (
                                            <button type="button" className="disabled">
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.74196 0H8.53325C8.04287 0 7.64497 0.401357 7.64497 0.895999V9.93216L4.84369 7.10654C4.49583 6.75651 3.93334 6.75651 3.58633 7.10654L3.43955 7.25544L3.43955 7.25546H3.4387C3.09169 7.60549 3.09169 8.17287 3.43955 8.52289L7.79864 12.919C7.80051 12.9209 7.80239 12.9228 7.80428 12.9246C7.96484 13.1622 8.23154 13.3494 8.53325 13.3494H8.74196C9.08618 13.3494 9.38483 13.1517 9.53228 12.8627L13.8358 8.52266C14.1828 8.17263 14.1828 7.60525 13.8358 7.25523L13.6882 7.10632L13.6882 7.1063C13.3412 6.75627 12.7787 6.75627 12.4317 7.1063L9.63025 9.93207V0.895999C9.63025 0.494642 9.23235 0 8.74196 0ZM1.42779 13.8176H0.888194L0.888281 13.8176C0.3979 13.8176 0 14.2189 0 14.7136V17.2604C0 17.7551 0.3979 18.1564 0.888281 18.1564H16.4854C16.9767 18.1564 17.3746 17.7551 17.3746 17.2604V14.7136C17.3746 14.2189 16.9767 13.8176 16.4854 13.8176H15.9458C15.4555 13.8176 15.0576 14.2189 15.0576 14.7136V15.987L2.31607 15.8201V14.7136C2.31607 14.2189 1.91902 13.8176 1.42779 13.8176Z" fill="#DBDCE1"/>
                                                </svg>
                                            </button>
                                        )}
                                    </td>
                                    <td className="registration__button registration__button--invite">
                                    {inventory?.registration?.patient?.user?.emailAddress ? (
                                        inventory.registration.patient.user.emailVerified ? (
                                            <button type="button" className="invite t-sans disabled">{t('Verified')}</button>
                                        ): (
                                            <button type="button" onClick={() => handleInviteClick(inventory.registration.patient.user.emailAddress)} className="invite t-sans t-green">
                                                {inventory.registration.patient.user.verificationEmailSent ? t('Re invite') : t('Invite')}
                                            </button>
                                        )
                                    ) : (
                                        <button type="button" className="invite t-sans disabled">{t('Invite')}</button>
                                    )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>
                <div className="registrations-pagination">
                    <Pagination
                        pageCount={totalPages}
                        currentPage={(currentPage - 1)}
                        onPageChange={e => setCurrentPage(Number(e.selected) + 1)}
                    />
                </div>
            </section>
        </OrganizationPanelLayout>
    );
}