import React, { useEffect } from 'react';
import './search-box.component.scss';

export const SearchBox = ({ keywords, onChange }) => {    
    return (
        <div className="search-box registrations-filters__keywords">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.15 14.2123L12.1548 10.2171C12.8348 9.17973 13.226 7.93927 13.226 6.61303C13.226 2.97517 10.2682 0 6.613 0C2.95781 0 0 2.95783 0 6.61303C0 10.2682 2.95781 13.2261 6.613 13.2261C7.9392 13.2261 9.18046 12.8349 10.217 12.1549L14.2122 16.1501L16.15 14.2123ZM2.73666 6.61235C2.73666 4.46989 4.47058 2.73596 6.61303 2.73596C8.75548 2.73596 10.4894 4.46989 10.4894 6.61235C10.4894 8.75481 8.75548 10.4887 6.61303 10.4887C4.47058 10.4887 2.73666 8.75481 2.73666 6.61235Z" fill="#DBDCE1"/>
            </svg>
            <input placeholder="検索" value={keywords} onChange={onChange} />
        </div>
    );
}