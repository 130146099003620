import axios from '../config/axios';
import qs from 'query-string';
import fileDownload from 'js-file-download';

export const fetchOrganizationRegistrations = options => {
    return axios.get(`/api/organizations/view/all-inventories?${qs.stringify(options)}`);
}

export const invite = email => {
    return axios.post('/api/organizations/invite', { email });
}

export const organizationAddPatient = patient => {
    return axios.post('/api/organizations/add-new-patient', { patient });
}

export const downloadResultPdf = registrationId => {
    return axios.get(`/api/registrations/organization/print-result/${registrationId}`, { responseType: 'blob' }).then(response => {
        fileDownload(response.data, 'result.pdf');
    });
};

export const getOrganizationReport = options => {
    return axios.get(`/api/organizations/report?${qs.stringify(options)}`, { responseType: 'blob' }).then(response => {
        fileDownload(response.data, 'export.csv');
    });
};
