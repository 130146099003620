import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useI18next } from 'gatsby-plugin-react-i18next';

import './dropdown.component.scss';

const customStyles = {
    placeholder: provided => ({
        ...provided,
        fontSize: 12,
        letterSpacing: 1,
        lineHeight: '18px',
        fontWeight: 500,
        color: '#DBDCE1'
    }),
    control: (provided, state) => ({
        ...provided,
        minHeight: 40,
        cursor: 'pointer',
        borderColor: '#DBDCE1',
        boxShadow: state.isFocused && '0 0 0 1px #66AC46',
        ':hover': {
            borderColor: '#DBDCE1',
        }
    }),
    singleValue: provided => ({
        ...provided,
        fontSize: 12,
        color: '#2C3B4A',
        fontWeight: 700,
        letterSpacing: 1,
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        fontSize: 12,
        letterSpacing: 1,
        fontWeight: 500,
        color: state.isSelected ? '#fff' : '#2C3B4A',
        backgroundColor: state.isSelected && '#9CC988',
        transition: '300ms ease-in-out background-color',
        ':hover': {
            backgroundColor: '#E1EFDB',
        }
    })
}

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
          <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.08135 6.0308L9.75775 2.3544C10.0806 2.03155 10.0806 1.4994 9.75775 1.17659C9.43491 0.853742 8.90275 0.853742 8.57994 1.17659L5.49244 4.25411L2.40494 1.16661C2.0821 0.843766 1.54994 0.843766 1.22713 1.16661C1.06571 1.32804 0.979981 1.54661 0.979981 1.75554C0.979981 1.96446 1.06533 2.18304 1.22713 2.34446L4.90354 6.03094C5.22639 6.35378 5.75854 6.35378 6.08135 6.03094L6.08135 6.0308Z" fill="#404455"/>
            </svg>
        </components.DropdownIndicator>
      );
}

const IndicatorsContainer = props => {
    return (
        <components.IndicatorsContainer className="dropdown-indicatorsContainer" {...props} />
      );
}

export const Dropdown = ({ options, onChange, label, placeholder }) => {
    const { t } = useI18next();
    
    return (
        <div className="dropdown registrations-filters__status">
            <label className="t-sans t-grey9">{t(label)}</label>
            <Select
                className="dropdown-select"
                components={{
                    DropdownIndicator,
                    IndicatorsContainer,
                    IndicatorSeparator: () => null
                }}
                styles={customStyles}
                options={options}
                onChange={onChange}
                placeholder={t(placeholder)} />
        </div>
    );
}