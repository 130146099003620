import React from 'react';
import ReactPaginate from 'react-paginate';
import './crizit-paginate.component.scss';

const NextIndicator = () => (
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.24949 3.48312L2.98158 0.215204C2.6946 -0.0717726 2.22158 -0.0717727 1.93464 0.215204C1.64766 0.50218 1.64766 0.975204 1.93464 1.26215L4.67021 4.00659L1.92577 6.75104C1.63879 7.03801 1.63879 7.51104 1.92577 7.79798C2.06926 7.94147 2.26355 8.01767 2.44926 8.01767C2.63497 8.01767 2.82926 7.9418 2.97275 7.79798L6.24961 4.53006C6.53659 4.24309 6.53659 3.77006 6.24961 3.48312L6.24949 3.48312Z" fill="#404455"/>
    </svg>
);

const PrevIndicator = () => (
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.75051 5.51688L6.01842 8.78479C6.3054 9.07177 6.77842 9.07177 7.06536 8.78479C7.35234 8.49782 7.35234 8.02479 7.06536 7.73785L4.32979 4.99341L7.07423 2.24896C7.36121 1.96199 7.36121 1.48896 7.07423 1.20202C6.93074 1.05853 6.73645 0.982331 6.55074 0.982331C6.36503 0.982331 6.17074 1.0582 6.02725 1.20202L2.75039 4.46994C2.46341 4.75691 2.46341 5.22994 2.75039 5.51688L2.75051 5.51688Z" fill="#404455" />
    </svg>
)

export const Pagination = ({ pageCount = 1, onPageChange, currentPage }) => {
    return (
        <ReactPaginate
            previousLabel={<PrevIndicator />}
            nextLabel={<NextIndicator />}
            breakLabel={'...'}
            pageCount={pageCount}
            breakClassName={'break-me'}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={onPageChange}
            containerClassName={'crizit-pagination'}
            activeClassName={'active'}
            forcePage={currentPage}
        />
    );
}