import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import './status-label.component.scss';

export const StatusLabel = ({ registration }) => {
    const { t } = useI18next();
    if (!registration) return <span className="t-sans status-label status-label--unregistered">{t('Unregistered')}</span>;
    if (!registration.result) {
        switch (registration.status) {
            case 'PATIENT_COMPLETED':
                return <span className="t-sans status-label status-label--registered">{t('Patient Completed')}</span>;
            case 'RECEIVED_IN_LAB':
                return <span className="t-sans status-label status-label--received">{t('Received in lab')}</span>;
            case 'IN_TESTING':
                return <span className="t-sans status-label status-label--testing">{t('In testing')}</span>;
            case 'IN_PROGRESS':
                return <span className="t-sans status-label status-label--registered">{t('In Progress')}</span>;
            default:
                return <span className="t-sans status-label status-label--unknown">{t('Unknown')}</span>;
        }
    } else {
        switch (registration.result) {
            case 'POSITIVE':
                return <span className="t-sans status-label status-label--positive">{t('Positive')}</span>;
            case 'NEGATIVE':
                return <span className="t-sans status-label status-label--negative">{t('Negative')}</span>;
            default:
                return <span className="t-sans status-label status-label--unknown">{t('Unknown')}</span>;
        }
    }
}