import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import './table-header.component.scss';

export const TableHeader = ({ onCellClick }) => {
    const { t } = useI18next();
    return (
        <thead className="registration-table-header">
            <tr>
                <th className="t-sans t-grey9">
                    <div className="table-header-cell" onClick={() => onCellClick('barcode')}>
                        <span>{t('Test ID')}</span>
                        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.20759 3.02937C3.6079 2.50936 4.3921 2.50936 4.79241 3.02937L6.22473 4.89001C6.73092 5.54757 6.26216 6.5 5.43232 6.5L2.56768 6.5C1.73784 6.5 1.26908 5.54757 1.77527 4.89001L3.20759 3.02937Z" fill="#BABCC4"/>
                            <path d="M4.79241 11.9706C4.3921 12.4906 3.6079 12.4906 3.20759 11.9706L1.77527 10.11C1.26908 9.45242 1.73784 8.5 2.56768 8.5L5.43232 8.5C6.26216 8.5 6.73092 9.45242 6.22473 10.11L4.79241 11.9706Z" fill="#BABCC4"/>
                        </svg>
                    </div>
                </th>
                <th className="t-sans t-grey9 non-sortable">
                    <div className="table-header-cell">
                        <span>{t('Full Name')}</span>
                    </div>
                </th>
                <th className="t-sans t-grey9">
                    <div className="table-header-cell" onClick={() => onCellClick('testingdate')}>
                        <span>{t('Testing date')}</span>
                        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.20759 3.02937C3.6079 2.50936 4.3921 2.50936 4.79241 3.02937L6.22473 4.89001C6.73092 5.54757 6.26216 6.5 5.43232 6.5L2.56768 6.5C1.73784 6.5 1.26908 5.54757 1.77527 4.89001L3.20759 3.02937Z" fill="#BABCC4"/>
                            <path d="M4.79241 11.9706C4.3921 12.4906 3.6079 12.4906 3.20759 11.9706L1.77527 10.11C1.26908 9.45242 1.73784 8.5 2.56768 8.5L5.43232 8.5C6.26216 8.5 6.73092 9.45242 6.22473 10.11L4.79241 11.9706Z" fill="#BABCC4"/>
                        </svg>
                    </div>
                </th>
                <th className="t-sans t-grey9">
                    <div className="table-header-cell" onClick={() => onCellClick('email')}>
                        <span>{t('Email')}</span>
                        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.20759 3.02937C3.6079 2.50936 4.3921 2.50936 4.79241 3.02937L6.22473 4.89001C6.73092 5.54757 6.26216 6.5 5.43232 6.5L2.56768 6.5C1.73784 6.5 1.26908 5.54757 1.77527 4.89001L3.20759 3.02937Z" fill="#BABCC4"/>
                            <path d="M4.79241 11.9706C4.3921 12.4906 3.6079 12.4906 3.20759 11.9706L1.77527 10.11C1.26908 9.45242 1.73784 8.5 2.56768 8.5L5.43232 8.5C6.26216 8.5 6.73092 9.45242 6.22473 10.11L4.79241 11.9706Z" fill="#BABCC4"/>
                        </svg>
                    </div>
                </th>
                <th className="t-sans t-grey9" colSpan="4">
                    <div className="table-header-cell" onClick={() => onCellClick('status')}>
                        <span>{t('Test status')}</span>
                        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.20759 3.02937C3.6079 2.50936 4.3921 2.50936 4.79241 3.02937L6.22473 4.89001C6.73092 5.54757 6.26216 6.5 5.43232 6.5L2.56768 6.5C1.73784 6.5 1.26908 5.54757 1.77527 4.89001L3.20759 3.02937Z" fill="#BABCC4"/>
                            <path d="M4.79241 11.9706C4.3921 12.4906 3.6079 12.4906 3.20759 11.9706L1.77527 10.11C1.26908 9.45242 1.73784 8.5 2.56768 8.5L5.43232 8.5C6.26216 8.5 6.73092 9.45242 6.22473 10.11L4.79241 11.9706Z" fill="#BABCC4"/>
                        </svg>
                    </div>
                </th>
            </tr>
        </thead>
    );
}